* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  text-align: center;
  font-family: "Quicksand", sans-serif;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.container {
  padding: 0px 12px;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

hr {
  border: 1px solid #bdbdbd;
  width: 70%;
  margin: 30px auto;
}

/* ----------------HEADER (start)-------------- */
.header {
  background: #e6e6e6;
  margin-bottom: 25px;
}

.header-logo {
  max-width: 130px;
  width: 100%;
  margin: 16px 0px 8px;
}

.logo-subtitle {
  color: #191919;
  font-size: 14px;
  font-weight: 400;
}

.navbar {
  padding: 30px 12px 20px;
  display: flex;
  font-size: 14px;
  justify-content: center;
  font-weight: 400;
  gap: 40px;
}

.nav-link {
  color: #191919;
  padding: 3px 8px;
  letter-spacing: 0.5px;
}

.nav-link:hover,
.nav-link.active {
  background: #bdbdbd;
  border-radius: 6px;
}

/* ----------------HEADER (end)-------------- */

/* ----------------FOOTER (start)------------ */

.caption {
  font-size: 12px;
  margin: 50px 0px 10px;
}

.caption-link {
  color: #191919;
}

.caption-link:hover {
  color: #aacae4;
  font-weight: 800;
  text-decoration: underline;
}

/* ----------------FOOTER (end)------------ */

.welcome-pic {
  height: 250px;
  max-width: 100%;
  padding: 15px;
}

.welcome-title {
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 7px;
  margin: 18px 0px;
}

.welcome-subtitle {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 3px;
  color: #191919;
}

.about-title,
.stereo-title {
  font-size: 20px;
  font-weight: 500;
  word-spacing: 4px;
  letter-spacing: 4px;
  color: #191919;
}

.about-descp,
.setreo-descp {
  margin: 20px auto;
  line-height: 24px;
  width: 60%;
}

/*-------------- PRODUCT PAGE  & GALLERY PAGE-------------- */

.product-descp {
  font-size: 14px;
}

.product-subdescp {
  margin: 3px 0px 15px;
  font-size: 14px;
}

.products-img,
.gallery-img {
  height: 200px;
  max-width: 100%;
  padding: 15px;
}
.gallery-img {
  height: 300px;
}
.products-btn,
.gallery-btn {
  display: flex;
  margin: auto;
  padding: 7px 12px;
  width: fit-content;
  background: #aacae4;
  color: #191919;
  border-radius: 13px;
  font-weight: 500;
}
.products-btn:hover,
.gallery-btn:hover {
  background: #bdbdbd;
}
.prodcut-title,
.gallery-title {
  font-weight: 100;
  letter-spacing: 5px;
  font-size: 18px;
  font-weight: 400;
}

.gallery-title {
  margin-bottom: 10px;
}

.social-img {
  height: 45px;
  max-width: 100%;
  padding: 2px;
  margin-bottom: 5px;
}

/*------------------ CONTACT PAGE--------------------  */
.contact {
  font-size: 18px;
  font-weight: 400;
  margin: 10px 0px;
  letter-spacing: 2px;
}

.contact-user {
  letter-spacing: 6px;
}

.address {
  max-width: 400px;
  margin: auto;
  line-height: 24px;
}

.founder {
  font-size: 14px;
  letter-spacing: 1px;
}

/*------------------ Responsive Code------------  */
@media (max-width: 576px) {
  .navbar {
    gap: 0px;
  }
  hr {
    width: 90%;
  }
  .about-descp,
  .setreo-descp {
    width: 100%;
  }
  .welcome-pic {
    max-width: 300px;
  }
}

.form-group {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.form-elemrnt {
  margin: 5px;
  width: 98%;
}

.form-elemrnt input {
  width: 100%;
  border: 1px solid #f2f2f2;
  color: #000;
  padding: 10px;
  border-radius: 6px;
  margin: 0;
  font-size: 16px;
}

.checkout-container {
  max-width: 600px;
  margin: auto;
}

.checkout-container h1 {
  margin-bottom: 20px;
}

.product-cont {
  display: flex;
  margin: 25px 0;
  align-items: center;
  justify-content: center;
}

.product-cont .product-img {
  max-width: 200px;
}

.product-cont .product-img img {
  max-width: 100%;
}

.product-content {
  margin-left: 20px;
}

.product-content h4 {
  text-align: initial;
  font-size: 18px;
  margin-bottom: 10px;
}

.product-content .form-elemrnt {
  max-width: 70px;
}
